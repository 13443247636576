@import "settings";

* {
  box-sizing: border-box;
}
html {
  font-size: 62.5%;
}
body {
  font-family: $font-roboto;
  font-size: $m-size;
  line-height: 1.2;
  letter-spacing: 0.2rem;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column
}
a {
  text-decoration: none;
}
p {
  margin: 0;
}
.spacer {
  margin-left: auto;
}