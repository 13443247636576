@import "mixins";

// Colors Social
$facebook: #3B5998;
$twitter: #000000;
$googleplus: #C63D2D;
$linkedin: #4875B4;
$instagram: #c1558b;

// Colors Grays
$off-white: #F5F5F5;
$light-grey: #CCCCCC;
$white: #ffffff;
$black: #000000;
$off-black: #202020;
$disabled-bg: #E7E7E7;
$disabled-color: #888888;

// Fonts
$font-roboto: "RobotoMono", Helvetica, Arial, sans-serif;

// Font Size
$font-size-large: 1.8rem;
$font-size-small: 1.4rem;

// Spacing
$xs-size: 1.2rem;
$s-size: 1.4rem;
$m-size: 1.6rem;
$l-size: 2rem;
$xl-size: 3.2rem;
$xxl-size: 4.8rem;

// Tooltip
$zindex-tooltip: 9;
$tooltip-margin: 0;
$tooltip-opacity: 0.9;
$tooltip-arrow-width: 0.8rem;
$tooltip-arrow-height: 0.4rem;
$tooltip-arrow-color: #000;
$tooltip-max-width: 20rem;
$tooltip-padding-y: 0.25rem;
$tooltip-padding-x: 0.5rem;
$tooltip-color: #fff;
$tooltip-bg: #000;
$tooltip-border-radius: 0.25rem;