@font-face {
  font-family: "RobotoMono";
  src: url("./../../assets/fonts/RobotoMono-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "RobotoMono";
  src: url("./../../assets/fonts/RobotoMono-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: "RobotoMono";
  src: url("./../../assets/fonts/RobotoMono-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "RobotoMono";
  src: url("./../../assets/fonts/RobotoMono-SemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "RobotoMono";
  src: url("./../../assets/fonts/RobotoMono-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "RobotoMono";
  src: url("./../../assets/fonts/RobotoMono-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "RobotoMono";
  src: url("./../../assets/fonts/RobotoMono-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "RobotoMono";
  src: url("./../../assets/fonts/RobotoMono-Italic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "RobotoMono";
  src: url("./../../assets/fonts/RobotoMono-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "RobotoMono";
  src: url("./../../assets/fonts/RobotoMono-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "RobotoMono";
  src: url("./../../assets/fonts/RobotoMono-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "RobotoMono";
  src: url("./../../assets/fonts/RobotoMono-ExtraLightItalic.ttf") format("truetype");
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: "RobotoMono";
  src: url("./../../assets/fonts/RobotoMono-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "RobotoMono";
  src: url("./../../assets/fonts/RobotoMono-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
}
