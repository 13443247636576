@import "settings";

#container {
  &.hidden {
    display: none;
  }
}
#loader {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: $off-white;
  z-index: 99;
  &.fadeIn {
    opacity: 1;
    transition: opacity 1.4s ease-in-out;
  }
  &.fadeOut {
    opacity: 0;
  }
  &.hidden {
    display: none;
  }
}
.hideBody {
  visibility: hidden;
}
.animateBody {
  overflow: hidden;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.home-body {
  background-color: $off-white;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  background-image: none;

  display: flex;
  flex-direction: column;
  height: 100%;
  // overflow-y: hidden;
  @media (min-width: 1440px) {
    background-image: url("./../../assets/img/new_lines.svg");
    background-position: 15px top;
  }
  @media (min-width: map-get($grid-breakpoints, xl)) {
    background-position: 32px top;
  }
  @media (min-width: map-get($grid-breakpoints, xxl)) {
    background-position: 60px top;
  }

  &.scrolled {
    z-index: 1;
    &::before {
      content: "";
      background-image: url("./../../assets/img/half_sphere.svg");
      background-repeat: no-repeat;
      background-size: contain;
      height: 414px;
      width: 170px;
      position: absolute;
      right: 0;
      top: 50%;
      margin-top: -170px;
      opacity: 0;
      animation: fadeIn 2s ease-in forwards;
      z-index: 0;
    }
  }
}

.primary {
  display: inline-block;
  cursor: pointer;
  color: $white;
  background-color: $black;
  border: 1px solid $black;
  text-transform: uppercase;
  padding: 10px 20px;
  font-weight: 100;
  font-size: $s-size;
  font-family: $font-roboto;
  transition: background-color 0.2s linear;
  &:hover,
  &.active,
  &:focus {
    background-color: transparent;
    color: $black;
  }
}

.secondary {
  @extend .primary;
  color: $black;
  background-color: $light-grey;
  border-color: $light-grey;
  &:hover,
  &.active,
  &:focus {
    color: $white;
    background-color: $black;
    border-color: $black;
  }
}

.link {
  @extend .primary;
  color: $black;
  background-color: transparent;
  border-color: $light-grey;
  letter-spacing: 2px;
  &:hover,
  &.active,
  &:focus {
    color: $white !important;
    background-color: $black;
    border-color: $black;
  }
}

.header {
  display: flex;
  width: 100%;
  top: 0;
  position: relative;
  z-index: 2;
}

#body.scrolled .header {
  display: none;
}

.navbar {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 3rem 0;

  @media (max-width: map-get($grid-breakpoints, lg)) {
    padding: 1.5rem;
    transform: scale(0.8);
    transform-origin: top right;
  }
  @media (max-width: map-get($grid-breakpoints, sm)) {
    padding: 1rem;
    transform: scale(0.6);
    transform-origin: top right;
  }
}

.main {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  // 196px is the footer size.
  // height: calc(100vh - 196px);
  padding-top: 7rem;

  @media (max-width: map-get($grid-breakpoints, lg)) {
    // height: calc(100vh - 196px);
    padding-top: 98px;
    // padding-top: 56px;
    padding-top: clamp(44px, 8vh, 98px);
  }
  @media (max-width: map-get($grid-breakpoints, sm)) {
    // height: calc(100vh - 196px);
    // padding-top: 98px;
    // padding-top: 35px;
    padding-top: clamp(30px, 6vh, 98px);
  }
}

.join-button {
  width: 100%;
  // max-width: 292px;
  text-align: center;
  // margin-top: 1rem;
  background-color: #f5f5f5;
}
.register-button {
  width: 100%;
  max-width: 450px;
  text-align: center;
  margin-top: 1rem;
  background-color: #f5f5f5;
}

.connect-button {
  display: flex;
  align-items: center;
  gap: 1rem;
  &::before {
    content: "";
    background-image: url("./../../assets/img/metamask_logo.svg");
    background-repeat: no-repeat;
    background-size: contain;
    width: 28px;
    height: 26px;
  }
}

.add-network-button {
  display: flex;
  align-items: center;
  gap: 1rem;
  &::before {
    content: "";
    background-image: url("./../../assets/img/globe_icon.svg");
    background-repeat: no-repeat;
    background-size: contain;
    width: 28px;
    height: 26px;
  }
}

section {
  width: 100%;
}

.hero {
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: 100%;
  // overflow-y: hidden;
  z-index: 1;

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: 0 1rem;
    // @media (min-width: map-get($grid-breakpoints, lg)) {
    //   padding: 0;
    // }
  }

  &::before {
    content: "";
    position: fixed;
    top: 0px;
    right: 0%;
    width: 100%;
    height: 100vh;
    background-image: url("./../../assets/img/curved-lines.svg");
    background-repeat: no-repeat;
    background-position: right center;
    background-size: contain;
    z-index: -1;
    pointer-events: none;

    @media screen and (width <= (map-get($grid-breakpoints, md))) {
      background-size: cover;
    }
  }

  &.active {
    display: flex;
  }

  &__line-css {
    position: absolute;
    height: 100vh;
    pointer-events: none;
    top: 16px;
    left: 0px;
    width: calc(50% - calc(32px / 2));

    &__vertical {
      content: "";
      position: absolute;
      top: 50px;
      left: 0px;
      height: calc(100% - 50px);
      width: 1px;
      background-color: #cccccc;
    }

    &:before {
      content: "";
      position: absolute;
      top: 0px;
      left: 50px;
      width: calc(100% - 50px);
      height: 1px;
      background-color: #cccccc;
    }

    &:after {
      content: "";
      position: absolute;
      top: 50px;
      left: 0px;
      width: 70px;
      height: 1px;
      background-color: #cccccc;
      transform: rotate(-45deg);
      transform-origin: top left;
    }

    @media (min-width: map-get($grid-breakpoints, sm)) {
      left: 0;
      width: calc(50% - calc(32px / 2));
    }
    @media (min-width: map-get($grid-breakpoints, md)) {
     
    }
    @media (min-width: map-get($grid-breakpoints, lg)) {
      left: 0;
      width: calc(50% - calc(74px / 2));
    }
    @media (min-width: map-get($grid-breakpoints, xl)) {
    }
    @media (min-width: map-get($grid-breakpoints, xxl)) {
    }
  }

  &__line {
    position: absolute;
    top: 15px;
    left: 20px;
    width: 160px;
    pointer-events: none;
    @media screen and (min-width: 992px) {
      left: 133px;
      width: 400px;
    }

    //
    // With max-width
    //

    // top: 15px;
    // left: 133px;
    // width: 400px;

    // @media (max-width: 1372px) {
    //   top: 15px;
    //   left: 133px;
    //   width: 400px;
    // }

    // @media (max-width: map-get($grid-breakpoints, xl)) {
    //   top: 16px;
    //   left: 3px;
    //   width: 320px;
    // }

    // @media (max-width: map-get($grid-breakpoints, lg)) {
    //   left: 23px;
    //   width: 300px;
    //   top: 17px;
    // }
    // @media (max-width: map-get($grid-breakpoints, sm)) {
    //   top: 15px;
    //   left: 20px;
    //   width: 160px;
    // }

    //
    // With min-width
    //
    // top: 15px;
    // left: 10px;
    // width: 160px;

    // @media (width >= 576px) {
    //   top: 15px;
    //   left: 20px;
    //   width: 160px;
    // }
    // @media (width >= 768px) {
    //   left: 23px;
    //   width: 300px;
    //   top: 17px;
    // }
    // @media (width >= 1200px) {
    //   top: 16px;
    //   left: 3px;
    //   width: 320px;
    // }
    // @media (width >= 1400px) {
    //   max-width: 1140px;
    // }
    // @media (width >= 1900px) {
    //   top: 15px;
    //   left: 133px;
    //   width: 400px;
    // }
  }
  &__logo {
    height: 70px;
    margin-bottom: 3rem;
    max-width: 90%;
  }
  &__small-logo {
    width: 74px;
    height: auto;
    &--container {
      position: relative;
      height: auto;
      width: 74px;
      margin-bottom: 50px;
    }
    &--static {
      position: absolute;
      top: 4px;
      left: 0;
      width: 74px;
    }
    @media (max-width: map-get($grid-breakpoints, lg)) {
      width: 40px;
      height: auto;
      &--container {
        width: 40px;
        margin-bottom: 30px;
        margin-top: 8px;
      }
      &--static {
        top: 2px;
        left: 0;
        width: 40px;
      }
    }
    @media (max-width: map-get($grid-breakpoints, sm)) {
      width: 32px;
      height: auto;
      &--container {
        width: 32px;
        margin-bottom: 20px;
        margin-top: 8px;
      }
      &--static {
        top: 2px;
        left: 0;
        width: 32px;
      }
    }
  }
  &__text {
    margin-bottom: 5rem;
    font-weight: 300;
    font-style: italic;
    font-size: $l-size;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    white-space: nowrap;

    .break-words {
      display: none;
      flex-wrap: nowrap;
    }
    @media (max-width: map-get($grid-breakpoints, lg)) {
      margin-bottom: 3rem;
      .break-words {
        display: block;
      }
    }
    @media (max-width: map-get($grid-breakpoints, sm)) {
      margin-bottom: 2rem;
      .break-words {
        display: block;
      }
    }
  }
  &__buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    &--container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      gap: 1rem;
      margin-bottom: 4rem;
    }
    .dropdown-selected {
      min-width: 200px;
    }
    .secondary {
      min-width: 200px;
    }
  }
  @media screen and (max-width: 768px) {
    .dropdown-selected {
      min-width: 100%;
    }
    .secondary {
      min-width: 100%;
    }
    &__text {
      font-size: $s-size;
    }
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideDown {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.slide-up {
  animation: slideUp 1s ease-in forwards;
}
.slide-down {
  animation: slideDown 1s ease-in forwards;
}

.steps {
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 40px;
  position: relative;
  height: 100%;
  &.active {
    display: flex;
  }
  &__copy {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 3rem;
    max-width: 90vw;
    overflow: scroll;

    button {
      background: none;
      border: none;
      cursor: pointer;
    }
    &:hover {
      background: transparent;
      color: $black;
      border-color: $light-grey;
    }
  }
  &__container {
    margin: auto 0;
  }
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__line {
    // width: 290px;
    width: 100%;

    height: 170px;
    position: absolute;
    bottom: 45px;
    left: -20px;

    @media screen and (min-width: 556px) {
      width: 90%;
    }

    @media screen and (min-width: 992px) {
      width: 530px;
    }

    @media screen and (min-width: 1400px) {
      width: 725px;
      height: 175px;
      bottom: 45px;
      left: 33px;
    }
  }
  &__header {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    position: relative;
  }
  &__list {
    z-index: 1;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 646px;
    width: 100%;
    li {
      display: flex;
      align-items: center;
      justify-content: center;
      button {
        width: 68px;
        height: 68px;
        border-radius: 50%;
        background: $disabled-bg;
        color: $disabled-color;
        text-align: center;
        vertical-align: middle;
        border: none;
        font-weight: 300;
        font-size: 18px;
        font-family: $font-roboto;
        cursor: pointer;
        &.active {
          background: $white;
          color: $black;
        }
      }
    }
  }
  &__text {
    margin-top: 5rem;
    margin-bottom: 3rem;
    font-weight: 300;
    font-size: $m-size;
    text-align: center;
    text-transform: uppercase;
  }
  @media screen and (max-width: 768px) {
    &__text {
      font-size: $s-size;
    }
  }
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 0;
  width: 100%;
  bottom: 0;
  z-index: 1;

  &__text {
    font-size: $s-size;
    text-transform: uppercase;
    font-weight: 200;
    text-align: center;
  }
  &__social {
    list-style: none;
    padding: 0;
    display: flex;
    align-items: center;
    gap: 4rem;
    margin-bottom: 2rem;
  }
  &__social-icon {
    content: "";
    position: relative;
    width: 36px;
    height: 36px;
    display: block;
    background-repeat: no-repeat;
    background-size: contain;
    transition: background-image 0.2s ease-in-out;
    &.icon-x {
      background-image: url("./../../assets/img/x_normal_state.svg");
      &:hover,
      &.active {
        background-image: url("./../../assets/img/x_select.svg");
      }
    }
    &.icon-telegram {
      background-image: url("./../../assets/img/tele_normal_state.svg");
      &:hover,
      &.active {
        background-image: url("./../../assets/img/tele_select.svg");
      }
    }
    &.icon-discord {
      background-image: url("./../../assets/img/discord_normal_state.svg");
      &:hover,
      &.active {
        background-image: url("./../../assets/img/discord_select.svg");
      }
    }
  }
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 140px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -75px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
