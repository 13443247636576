// @use "variables.scss" as *;

@for $i from 1 through 7 {
  $size-increment: 0.8rem;

  .gap-#{$i} {
    gap: calc($size-increment * $i * $i/3) !important;
  }
}

@for $i from 0 through 7 {
  $size-increment: 0.8rem;

  @each $key, $breakpoint in $grid-breakpoints {
    @media (min-width: $breakpoint) {
      .m-#{$key}-#{$i} {
        margin: calc($size-increment * $i * $i/3) !important;
      }
      .mt-#{$key}-#{$i} {
        margin-top: calc($size-increment * $i * $i/3) !important;
      }
      .ml-#{$key}-#{$i} {
        margin-left: calc($size-increment * $i * $i/3) !important;
      }
      .mr-#{$key}-#{$i} {
        margin-right: calc($size-increment * $i * $i/3) !important;
      }
      .mb-#{$key}-#{$i} {
        margin-bottom: calc($size-increment * $i * $i/3) !important;
      }

      .p-#{$key}-#{$i} {
        padding: calc($size-increment * $i * $i/3) !important;
      }
      .pt-#{$key}-#{$i} {
        padding-top: calc($size-increment * $i * $i/3) !important;
      }
      .pl-#{$key}-#{$i} {
        padding-left: calc($size-increment * $i * $i/3) !important;
      }
      .pr-#{$key}-#{$i} {
        padding-right: calc($size-increment * $i * $i/3) !important;
      }
      .pb-#{$key}-#{$i} {
        padding-bottom: calc($size-increment * $i * $i/3) !important;
      }
    }
  }

  .m-#{$i} {
    margin: calc($size-increment * $i * $i/3) !important;
  }
  .mt-#{$i} {
    margin-top: calc($size-increment * $i * $i/3) !important;
  }
  .ml-#{$i} {
    margin-left: calc($size-increment * $i * $i/3) !important;
  }
  .mr-#{$i} {
    margin-right: calc($size-increment * $i * $i/3) !important;
  }
  .mb-#{$i} {
    margin-bottom: calc($size-increment * $i * $i/3) !important;
  }

  .p-#{$i} {
    padding: calc($size-increment * $i * $i/3) !important;
  }
  .pt-#{$i} {
    padding-top: calc($size-increment * $i * $i/3) !important;
  }
  .pl-#{$i} {
    padding-left: calc($size-increment * $i * $i/3) !important;
  }
  .pr-#{$i} {
    padding-right: calc($size-increment * $i * $i/3) !important;
  }
  .pb-#{$i} {
    padding-bottom: calc($size-increment * $i * $i/3) !important;
  }
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-white {
  color: $white !important;
}

.text-black {
  color: $black !important;
}

.text-overflow-ellipsis {
  text-overflow: ellipsis;
}

.overflow-hidden {
  overflow: hidden;
}

.text-nowrap {
  white-space: nowrap;
}

.p-relative {
  position: relative !important;
}

.d-flex {
  display: flex !important;
}

.d-block {
  display: block !important;
}

.d-none {
  display: none !important;
}

.select-none {
  user-select: none !important;
}

.flex-columns {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around;
}

.justify-content-start {
  justify-content: flex-start;
}

.justify-content-end {
  justify-content: flex-end;
}

.align-items-end {
  align-items: flex-end;
}

.align-items-start {
  align-items: flex-start;
}

.align-items-center {
  align-items: center;
}

.transparent {
  opacity: 0.5;
}

.disabled {
  user-select: none;
  pointer-events: none;
  opacity: 0.5;
}

.soft-disabled {
  user-select: none;
  pointer-events: none;
  opacity: 1;
}

.w-100 {
  width: 100% !important;
}
