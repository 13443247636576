@import "./variables.scss";
@import "./styleguides.scss";
@import "./parts.scss";

#spinner-root {
  top: 0px;
  width: 100vw;
  left: 0;
  position: fixed;
  z-index: 3;
}

.container {
  padding: 0 2rem;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  @media (min-width: map-get($grid-breakpoints, sm)) {
    max-width: 540px;
  }
  @media (min-width: map-get($grid-breakpoints, md)) {
    max-width: 720px;
  }
  @media (min-width: map-get($grid-breakpoints, lg)) {
    max-width: 960px;
  }
  @media (min-width: map-get($grid-breakpoints, xl)) {
    max-width: 1140px;
  }
  @media (min-width: map-get($grid-breakpoints, xxl)) {
    max-width: 1320px;
  }
}

.floating-spinner-backdrop {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-user-select: none;
  user-select: none;
  background: rgba(0, 0, 0, 0.75);
  z-index: 3;
}

#extension-approval-spinner-root {
  position: relative;

  .floating-spinner-backdrop {
    top: 0px;
    left: 0;
    position: absolute;
    width: 100%;
    // height: calc(100% + 36px);
    height: 100%;
    border-radius: 0px 0px 30px 30px;
    z-index: 3;
    background: rgba(0, 0, 0, 0.85);
  }

  .spinner-container {
    margin-top: 0px !important;
    top: 50%;
    left: 0;
  }
}

.spinner-backdrop {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  user-select: none;
  background: rgba(0, 0, 0, 0.75);
  z-index: 3;
}

.spinner-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  position: absolute;
  margin-top: 50vh;
  margin-left: 50%;
  transform: translate(-50%, -50%);
  z-index: 4;

  .spinner {
    animation: rotate 1.5s infinite ease-in-out;
  }
}

.contextual-spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;

  .spinner {
    height: 1.25em;
    animation: rotate 1.25s infinite ease-in-out;
    user-select: none;
  }

  .spinner-tooltip {
    margin-top: 110px;
  }
}

img {
  pointer-events: none;
  user-select: none;
}

.border-top {
  border-top: 2px solid $black;
}

.extra-home-details {
  padding: 2rem 0;
  @media (min-width: map-get($grid-breakpoints, lg)) {
    max-width: map-get($grid-breakpoints, lg);
  }
}
.extra-details-container {
  align-items: flex-start;
  justify-content: center;
  display: flex;
  padding: 1rem 0rem;
  gap: 40px;
  position: relative;
  scroll-behavior: smooth;
  margin-top: 2rem;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    height: 0rem;
    width: 0rem;
  }

  .extra-details {
    width: 100%;
    font-size: 14px;
    align-items: start;
    display: flex;
    flex-direction: column;
    
    &__image {
      height: 60px;
      margin-bottom: 2rem;
    }

    &__content {
      width: 100%;
      padding-top: 2rem;
      text-align: left;
      border-top: 1px solid;
    }
  }

  @media (max-width: map-get($grid-breakpoints, lg)) {
    justify-content: flex-start;
    gap: 0;

    .extra-details {
      flex-shrink: 0; /* Ensure slides don't shrink */
    }
  }

  @media (max-width: map-get($grid-breakpoints, md)) {
    .arrows {
      right: 50%;
      bottom: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
.arrows-container {
  position: relative;
  height: calc(18px + 1rem);
  .arrows {
    gap: 2rem;
    position: absolute;
    display: none;
    & > div {
      cursor: pointer;
    }
    @media (max-width: map-get($grid-breakpoints, lg)) {
      display: flex;
      right: 50%;
      bottom: 1rem;
      transform: translate(50%, 0%);
    }
    @media (max-width: map-get($grid-breakpoints, md)) {
      display: flex;
      right: 1rem;
      bottom: 1rem;
      transform: translate(0%, 0%);
    }
  }
}

.coming-soon {
  font-size: 3rem;
  text-align: center;
  padding: 5rem 0;
  @media (min-width: map-get($grid-breakpoints, md)) {
    font-size: 8rem;
    text-align: center;
    padding: 8rem 0;
  }
}

.verified {
  display: flex;
  position: relative;
  gap: 2rem;
  padding: 5rem 0;
  flex-direction: column;
  @media (min-width: map-get($grid-breakpoints, md)) {
   flex-direction: row;
   gap: 5rem;
  }
  .line {
    display: block;
    width: 1px;
    background-color: black;
  }
  & > .col {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    h3 {
      margin: 0;
      font-size: 2rem; // text-lg
      text-transform: uppercase; // uppercase
    }

    img {
      height: 30px;
      width: auto;
    }
  }
}

/* Basic styling for the dropdown */
.dropdown-container {
  position: relative;
  display: flex;
  font-family: Arial, sans-serif;
}

.dropdown-label {
  display: block;
  margin-bottom: 8px;
}

.dropdown-selected {
  width: 100%;
  display: inline-flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid #000000;
  text-transform: uppercase;
  padding: 10px 20px;
  font-weight: 100;
  font-size: 1.4rem;
  font-family: "RobotoMono", Helvetica, Arial, sans-serif;
  transition: background-color 0.2s linear;
  color: #000000;
    background-color: #CCCCCC;
    border-color: #CCCCCC;
    &:hover, &:focus {
      color: #ffffff;
    background-color: #000000;
    border-color: #000000;
    }
}


.dropdown-options {
  width: 100%;
  min-width: 200px;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 100%;
  right: 0;
  width: 100%;
  margin-top: 0;
  padding: 0;
  list-style-type: none;
  border: 1px solid #ccc;
  background-color: #fff;
  z-index: 10;
}

.dropdown-option {
  width: 100%;
  &:not(:last-child) {
    a {
    border-bottom: 1px solid #ccc;
    }
  }
}

.dropdown-link {
  text-decoration: none;
  width: 100%;
  color: #333;
  text-align: center;
  display: block;
  padding: 10px;
  font-weight: 200;
  font-family: "RobotoMono", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
}

.dropdown-link:hover {
  background-color: #f0f0f0;
}

.dropdown-link:active {
  background-color: #e0e0e0;
}
